<template>
  <v-container fluid>
    <listitemspage
      :qSelect="Qselect"
      :qSelectall="Qselectall"
      :qDelete="Qdelete"
      :PageResponse="'templates'"
      :AllResponse="'alltemplates'"
      :title="'Liste des Modeles de Produits'"
      :headers="headers"
      :field="'CREATED_AT_TEMP'"
      :filename="'Modeles'"
      :sheetname="'Modeles'"
      :showedit="false"
      :del_disable="product.variantscount > 0"
      @open="OpenProductForm"
      @rowselect="ProductChange"
      :Add="$store.state.auth.includes('02018') || $store.state.isadmin"
      :del="$store.state.auth.includes('02018') || $store.state.isadmin"
    >
    </listitemspage>
    <templateform
      :item="product"
      :key="keyproduct"
      :showForm="!isProductClosed"
      @close="closeProductForm"
      :attributs="attributes"
      :values_list="values"
      :cat_list="cat_list"
      :barcodetypes="barcodetypes"
      :depots="depots"
      :units="units"
      :plancomptable="plancomptable"
    >
    </templateform>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
const listitemspage = () => import("../components/ListItemsPages.vue");
const templateform = () => import("../views/TemplateForm.vue");

import TEMPLATES from "../graphql/Template/TEMPLATES.gql";
import ALLTEMPLATES from "../graphql/Template/ALLTEMPLATES.gql";
import DELETE_TEMPLATE from "../graphql/Template/DELETE_TEMPLATE.gql";
import DATA from "../graphql/Template/DATA.gql";

export default {
  components: {
    listitemspage,
    templateform,
  },

  data: () => ({
    headers: [
      {
        text: "Ref.",
        value: "ref",
        enum: "REF",
        selected: true,
      },
      {
        text: "Libelle",
        align: "start",
        value: "label",
        enum: "LABEL",
        slot: "href",
        selected: true,
      },

      {
        text: "En Fabrication",
        value: "EnFabrication",
        sortable: false,
        selected: true,
        slot: "checkbox",
      },
      {
        text: "En vente",
        value: "EnVente",
        sortable: false,
        selected: true,
        slot: "checkbox",
      },
      {
        text: "En achat",
        value: "EnAchat",
        selected: true,
        sortable: false,
        slot: "checkbox",
      },
      {
        text: "Date de Création",
        value: "created_at",
        enum: "CREATED_AT_TEMP",
        slot: "date",
        selected: true,
      },
      {
        text: "Nombre variants",
        value: "variantscount",
        selected: true,
      },
    ],
    Qselect: {},
    Qselectall: {},
    Qdelete: {},
    Qdetaildelete: {},
    isProductClosed: true,
    product: {},
    attributes: [],
    values: [],
    cat_list: [],
    barcodetypes: [],
    depots: [],
    units: [],
    plancomptable: [],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    keyproduct() {
      return this.product.id;
    },
  },
  watch: {},
  created() {},

  mounted() {
    this.$store.dispatch("Changed", true);
    this.Qselect = TEMPLATES;
    this.Qselectall = ALLTEMPLATES;
    this.Qdelete = DELETE_TEMPLATE;
    this.get_data();
  },

  methods: {
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async get_data() {
      let r = await this.requette(DATA);
      if (r) {
        this.attributes = r.allattributes;
        this.values = r.allvalues;
        this.cat_list = r.categorie_2;
        this.barcodetypes = r.barcodetypes;
        this.depots = r.depots;
        this.units = r.units;
        this.plancomptable = r.plancomptable;
      }
    },
    ProductChange(item) {
      this.product = item;
    },
    OpenProductForm(item) {
      this.product = item;
      this.isProductClosed = false;
    },
    closeProductForm() {
      this.product = {};
      this.isProductClosed = true;
    },
  },
};
</script>
